import React from 'react';
import { func, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { Button, FieldLocationAutocompleteInput, Form } from '../../../components';

import css from '../SearchPage.module.css';
import IconSearchDesktop from '../../NotFoundPage/SearchForm/IconSearchDesktop';

const identity = v => v;

const SectionFilterFormComponent = props => {
  const { viewport } = props;
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          rootClassName,
          className,
          values,
          intl,
          isSearchPageFilter,
          handleSubmit,
        } = formRenderProps;

        const classes = classNames(rootClassName || css.root, className, {
          [css.searchPageFilter]: isSearchPageFilter,
        });

        const isMobileLayout = viewport.width > 0 && viewport.width < 768;
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <FieldLocationAutocompleteInput
              rootClassName={css.locationAddress}
              inputClassName={css.locationAutocompleteInput}
              iconClassName={css.locationAutocompleteInputIcon}
              predictionsClassName={css.predictionsRoot}
              validClassName={css.validLocation}
              name="location"
              label=""
              placeholder={intl.formatMessage({
                id: 'SectionFilterForm.locationPlaceholder',
              })}
              useDefaultPredictions={true}
              format={identity}
              valueFromForm={values.location}
              isSearchPageFilter={isSearchPageFilter}
            />
            <Button
              className={classNames({
                [css.searchIcon]: isSearchPageFilter,
                [css.searchBtn]: !isSearchPageFilter,
              })}
              onClick={() => props.onSubmit(values)}
              type="submit"
            >
              <IconSearchDesktop /> {isMobileLayout || isSearchPageFilter ? '' : 'Search'}
            </Button>
          </Form>
        );
      }}
    />
  );
};

SectionFilterFormComponent.defaultProps = { rootClassName: null, className: null };

SectionFilterFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SectionFilterForm = injectIntl(SectionFilterFormComponent);

export default SectionFilterForm;
