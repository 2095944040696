import React from 'react';
import classNames from 'classnames';
import pinImage from './images/OnBuoy-favicon-pin.png';
import greypinImage from './images/favicon-grey.png';
import { ensureListing } from '../../../util/data';

import css from './SearchMapIconPin.module.css';

const SearchMapIconPin = props => {
  const { rootClassName, className, listing, onListingClicked } = props;
  const isInfoOnly = listing?.attributes?.publicData?.infoOnly;

  const favicon = isInfoOnly ? greypinImage : pinImage;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  return (
    <div className={classes} onClick={() => onListingClicked(currentListing)}>
      <img className={css.pinImage} src={favicon} alt="Search Map Pin" />
    </div>
  );
};

export default SearchMapIconPin;
