import React from 'react';
import SectionFilterForm from './SectionFilterForm';
import css from '../SearchPage.module.css';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { createResourceLocatorString } from '../../../util/routes';

function SectionTopbarFilter(props) {
  const { intl, viewport, history, isSearchPageFilter } = props;

  const routeConfiguration = useRouteConfiguration();

  const handleSubmit = values => {
    const { location = {} } = values;
    const { search, selectedPlace = {} } = location;
    const { bounds } = selectedPlace;
    const searchQuery = {
      address: search,
      bounds,
      search,
    };

    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchQuery));
  };

  return (
    <div className={css.filterSection}>
      <SectionFilterForm
        rootClassName={css.searchPageForm}
        onSubmit={handleSubmit}
        intl={intl}
        viewport={viewport}
        isSearchPageFilter={isSearchPageFilter}
      />
    </div>
  );
}

export default SectionTopbarFilter;
